var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "auth",
    [
      [
        _c(
          "a-row",
          {
            staticClass: "full_height",
            attrs: { type: "flex", justify: "center", align: "middle" }
          },
          [
            !_vm.spin && !_vm.success
              ? _c("a-alert", {
                  attrs: {
                    type: "error",
                    message:
                      "Erreur dans la confirmation veuillez contacter votre administrateur",
                    banner: ""
                  }
                })
              : _vm._e(),
            _vm.success && !_vm.spin
              ? _c("a-alert", {
                  attrs: {
                    type: "success",
                    message:
                      " Votre inscription a été effectuée avec succès, veuillez vérifier votre mail pour confirmation",
                    banner: ""
                  }
                })
              : _vm._e(),
            _c("a-col", { staticClass: "height-100" }, [
              _vm.spin
                ? _c(
                    "div",
                    { staticClass: "example h-100" },
                    [
                      _c("a-spin", {
                        attrs: {
                          size: "large",
                          tip: "Confirmation...",
                          spinning: _vm.spin
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }